import './App.css';

const data = [
    { id: 1, videoId: '1r5x0jwd3s' },
    { id: 2, videoId: 'j5csb04w2h' },
    { id: 3, videoId: 'omwm07qh4w' },
    { id: 4, videoId: '12kylgyvjh' },
    { id: 5, videoId: 'rul5ask4jg' },
    { id: 6, videoId: 'y36kyam9ve' },
    { id: 7, videoId: 'r1g5mfq784' },
    { id: 8, videoId: 'frcirg31z3' },
    { id: 9, videoId: 'k85xi3ivvu' },
    { id: 10, videoId: 'qqfd74rbtj' },
    ];
    
    const data2 = [
    { id: 1, videoId: 'k85xi3ivvu' },
    { id: 2, videoId: 'wxilsolqyv' },
    { id: 3, videoId: 'z54f7lqshn' },
    { id: 4, videoId: 'qqfd74rbtj' },
    { id: 5, videoId: 'r1g5mfq784' },
    { id: 6, videoId: 'frcirg31z3' },
    { id: 7, videoId: 'j5csb04w2h' },
    { id: 8, videoId: 'omwm07qh4w' },
    { id: 9, videoId: '1r5x0jwd3s' },
    { id: 10, videoId: 'rul5ask4jg' },
    ];

function App() {
    return (
        <div className='App'>
            <div className='container'>
                <div className='carousel'>
                    {data.map(item => (
                        <div className='item' key={item.id}>
                            <div
                                className='wistia_responsive_padding'
                                style={{
                                    padding: '100.0% 0 0 0',
                                    position: 'relative',
                                    borderRadius: '8px',
                                    pointerEvents: 'none',
                                }}
                            >
                         <div
                                    className='wistia_responsive_wrapper'
                                    style={{
                                       
                                        left: 0,
                                        position: 'absolute',
                                        top: 19,
                                        width: '110%',
                                        borderRadius: '8px',
                                    }}
                                >
                                    {/* <div
                                        className={`videoFoam=true wistia_async_${item.videoId} wistia_embed`}
                                        style={{
                                            height: '100%',
                                            position: 'relative',
                                            width: '100%',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        &nbsp;
                                    </div> */}
                                    <iframe
                                        src={`https://fast.wistia.net/embed/iframe/${item.videoId}?seo=false&videoFoam=true`}
                                        title=' [Example Video] Wistia Video Essentials'
                                        allow='autoplay; fullscreen'
                                        allowTransparency='true'
                                        frameBorder={0}
                                        scrolling='no'
                                        className='wistia_embed'
                                        name='wistia_embed'
                                        width='100%'
                                        height='100%'
                                        style={{ borderRadius: '8px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='container' style={{ marginTop: '1.5rem' }}>
                <div className='carousel2'>
                    {data2.map(item => (
                        <div className='item' key={item.id}>
                            <div
                                className='wistia_responsive_padding'
                                style={{
                                    padding: '100.0% 0 0 0',
                                    position: 'relative',
                                    pointerEvents: 'none',
                                }}
                            >
                      <div
                                    className='wistia_responsive_wrapper'
                                    style={{
                                        height: '110%',
                                        left: 0,
                                        position: 'absolute',
                                        top: 19, 
                                        width: '110%',
                                    }}
                                >
                                    {/* <div
                                        className={`videoFoam=true wistia_async_${item.videoId} wistia_embed`}
                                        style={{
                                            height: '100%',
                                            position: 'relative',
                                            width: '100%',
                                        }}
                                    >
                                        &nbsp;
                                    </div> */}
                                    <iframe
                                        src={`https://fast.wistia.net/embed/iframe/${item.videoId}?seo=false&videoFoam=true`}
                                        title=' [Example Video] Wistia Video Essentials'
                                        allow='autoplay; fullscreen'
                                        allowTransparency='true'
                                        frameBorder={0}
                                        scrolling='no'
                                        className='wistia_embed'
                                        name='wistia_embed'
                                        width='100%'
                                        height='100%'
                                        style={{ borderRadius: '8px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default App;
